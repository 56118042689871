var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1000px",
        "no-click-animation": "",
        persistent: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Site Level Authority Manager")]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "warning", text: "" } },
                        [
                          _vm._v(
                            "This will overwrite any existing entries for the selected Location(s)"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.save } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-0 pb-0",
                          attrs: { cols: "12", md: "12" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.slaTypes,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Role",
                              outlined: "",
                            },
                            model: {
                              value: _vm.$v.form.roleId.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.roleId, "$model", $$v)
                              },
                              expression: "$v.form.roleId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("user-select", {
                            attrs: {
                              users: _vm.users,
                              label: "User",
                              noChips: !_vm.useChips,
                              max: _vm.max,
                              saveEmail: "",
                            },
                            on: {
                              handleUserSelect: function ($event) {
                                return _vm.handleUserSelect($event, "user")
                              },
                            },
                            model: {
                              value: _vm.$v.form.userEmails.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.userEmails,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.userEmails.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h5", [_vm._v("Locations")]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "checkbox",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "All" },
                            on: { change: _vm.handleAllLocations },
                            model: {
                              value: _vm.selectAllLocations,
                              callback: function ($$v) {
                                _vm.selectAllLocations = $$v
                              },
                              expression: "selectAllLocations",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.locations, function (location, i) {
                        return _c(
                          "v-col",
                          {
                            key: i,
                            staticClass: "checkbox",
                            attrs: { cols: "12", md: "3" },
                          },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                value: location.id,
                                label: location.name,
                                multiple: "",
                              },
                              on: { change: _vm.handleLocationChange },
                              model: {
                                value: _vm.$v.form.locationIds.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.form.locationIds,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.form.locationIds.$model",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.saving,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }